
import './common/libs';

// Function Loadmodule
const loadModule = (name, element) => {
    switch (name) {
        case 'word-counter':
            import("./modules/wordCount").then( ( { wordCount } ) => { wordCount(element); } );
            break;
        default:
            console.log(`Module not registered: "${name}"`);
            break;
    }
};

const setObserver = (sectionModule, modules) => {

    let options = {
        root: null,
        rootMargin: '250px 0px 250px 0px',
        threshold: 0.2
    };

    let callback = (entries, observer) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                modules.forEach(module => { loadModule(module.name, module.element); });
                if(sectionModule!==null){ observer.unobserve(sectionModule); }
            }
        });
    };
    
    let observer = new IntersectionObserver(callback, options);
    if(sectionModule!==null){ observer.observe(sectionModule); }
}


window.addEventListener('load', function() {
    
    // Get data module
    const dataModule = document.querySelectorAll('[data-module]');
    const immediateModule = ['navigation',];
    const lastItem = (dataModule.length - 1);
    
    // Init loadmodule
    if (dataModule.length) {
        
        let previousSectionGroup = null;
        let moduleSectionGroup = [];

        dataModule.forEach((element, key) => {
            
            const name = element.dataset.module;
            const needImmediate = element.dataset.moduleImmediate ? ((element.dataset.moduleImmediate == "true") ? true : false) : false;
            const sectionModule = element.closest('section') ? element.closest('section') : element;
            const isImmediate =  immediateModule.includes(name) || needImmediate;

            if (window.IntersectionObserver && !isImmediate) {
                
                // if module in different section
                if(previousSectionGroup != sectionModule) { 
                    
                    // load module group first
                    if(previousSectionGroup != null) { setObserver(previousSectionGroup, moduleSectionGroup); }
                    
                    // then clean up module group, push new item, and update section group
                    moduleSectionGroup = []; 
                    moduleSectionGroup.push({ name: name, element: element }); 
                    previousSectionGroup = sectionModule;

                    // if last item, push then call
                    if(key == lastItem) { setObserver(previousSectionGroup, moduleSectionGroup); }

                }
                // if module still in the same section
                else { 
                    
                    moduleSectionGroup.push({ name: name, element: element });

                    // if last item, push then call
                    if(key == lastItem) { setObserver(previousSectionGroup, moduleSectionGroup); }
                }

            } else {
                
                // if last item, push then call
                if(key == lastItem) { setObserver(previousSectionGroup, moduleSectionGroup); }
                loadModule(name, element);

            }

        });
    }
});